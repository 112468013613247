import React, { useState, useEffect, memo } from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setCondensedView } from 'src/redux/slices/bettingPicksSlice';
import { Flex, Button, Segmented, Spin } from 'antd';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CalendarOutlined,
  StarFilled,
  OrderedListOutlined,
  VerticalAlignMiddleOutlined,
  ColumnHeightOutlined,
} from '@ant-design/icons';
import { Routes, Route, useParams, useNavigate, useLocation } from 'react-router-dom';
import { OddsEvent, Sport } from '../../types';
import {
  useGetOddsSportsQuery,
  useGetOddsSportEventsQuery,
  useLazyGetOddsSportEventsQuery,
} from 'src/redux/queries/bettingApi';
import BettingEvent from './BettingEvent';
import BettingEventMarkets from './BettingEventMarkets';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import OurPicks from './OurPicks/OurPicks';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { RootState } from 'src/redux/store';

import './Betting.scss';

const groupEventsByDate = (events: OddsEvent[]) => {
  if (!events) return {};

  return events.reduce((obj: Record<string, OddsEvent[]>, event: OddsEvent) => {
    if (dayjs().isAfter(dayjs(event.commence_time))) return obj;

    const date = dayjs(event.commence_time).format('YYYY-MM-DD');
    if (obj[date]) {
      obj[date].push(event);
    } else {
      obj[date] = [event];
    }
    return obj;
  }, {});
};

const getEventId = (url: string): string | null => {
  const parts = url.split('/');
  if (parts[parts.length - 1] !== 'betting') {
    return parts[parts.length - 1];
  }

  return null;
};

function Betting() {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { sportId = '' } = useParams();
  const [eventId, setEventId] = useState<string | null>(null);
  const [segment, setSegment] = useState('ourpicks');
  const { data: oddsSports, isFetching: oddsSportsFetching } = useGetOddsSportsQuery();

  const condensedView = useSelector((state: RootState) => state.bettingPicks.condensedView);

  const sportsKey = oddsSports?.find(sport => sport.title.toLowerCase() === sportId.toLowerCase())?.key;

  const {
    data: sportEvents,
    isFetching: sportEventsFetching,
    isLoading: sportEventsLoading,
  } = useGetOddsSportEventsQuery({ sportsKey: sportsKey as string }, { skip: !sportsKey });

  const [getOddsSportEvents] = useLazyGetOddsSportEventsQuery();

  const [dateScrollIndex, setDateScrollIndex] = useState(0);

  const handleScrollLeft = () => {
    if (dateScrollIndex > 0) {
      setDateScrollIndex(dateScrollIndex - 1);
    }
  };

  const handleScrollRight = () => {
    if (dateScrollIndex < Object.keys(eventGroups).length - 1) {
      setDateScrollIndex(dateScrollIndex + 1);
    }
  };

  const handleRefresh = async () => {
    await getOddsSportEvents({ sportsKey: sportsKey as string });
  };

  useEffect(() => {
    return () => {
      setSegment('ourpicks');
    };
  }, []);

  useEffect(() => {
    if (isTabletOrMobile) {
      if (getEventId(location.pathname)) {
        setEventId(getEventId(location.pathname));
        return;
      }

      return;
    }
    // if (!eventId && sportEvents?.length) {
    //   setEventId(getEventId(location.pathname) || sportEvents[0].id);
    // }

    if (sportEvents?.length) {
      setEventId(getEventId(location.pathname) || sportEvents[0].id);
    }
  }, [sportEvents, eventId, isTabletOrMobile, location.pathname]);

  const handleNavigate = (eventId: string) => {
    setEventId(eventId);
    navigate(`/sports/${sportId}/betting/${eventId}`);
  };

  if (sportEventsFetching || oddsSportsFetching)
    return (
      <Flex style={{ height: 300 }} align="center" justify="center">
        <Spin />
      </Flex>
    );

  if ((!sportsKey && !oddsSportsFetching && !sportEventsFetching) || (sportEvents && sportEvents.length === 0))
    return (
      <PullToRefresh onRefresh={handleRefresh}>
        <div style={{ padding: 16, height: 300 }}>There are no {sportId.toUpperCase()} events available</div>
      </PullToRefresh>
    );

  const eventGroups = groupEventsByDate(sportEvents as OddsEvent[]);

  const BettingLanding = memo(({ isTabletOrMobile }: { isTabletOrMobile: boolean }) => {
    const bettingCss = cn('betting-events-container', { 'betting-events-container--mobile': isTabletOrMobile });

    return (
      <Flex vertical={true} className={bettingCss}>
        {isTabletOrMobile && (
          <Flex align="center" gap={8} justify="space-between" style={{ marginBottom: 8, paddingTop: 8 }}>
            <div className={'betting-events__segment'}>
              <Segmented
                block
                value={segment}
                onChange={setSegment}
                options={[
                  { label: 'Top Picks', value: 'ourpicks', icon: <StarFilled /> },
                  { label: 'By Game', value: 'bygame', icon: <OrderedListOutlined /> },
                ]}
              />
            </div>
            <Button
              icon={<ColumnHeightOutlined />}
              ghost={condensedView}
              size={'middle'}
              shape="circle"
              type="primary"
              onClick={() => dispatch(setCondensedView({ condensedView: !condensedView }))}
            />
            <Button
              icon={<VerticalAlignMiddleOutlined />}
              ghost={!condensedView}
              size={'middle'}
              shape="circle"
              type="primary"
              onClick={() => dispatch(setCondensedView({ condensedView: !condensedView }))}
            />
          </Flex>
        )}
        {segment === 'ourpicks' && isTabletOrMobile && (
          <OurPicks sportId={sportId as Sport} condensed={condensedView} />
        )}
        {(segment === 'bygame' || !isTabletOrMobile) && (
          <>
            {Object.values(eventGroups).length === 0 && !sportEventsLoading ? (
              <div>No events available</div>
            ) : (
              <>
                <div className="date-scroll__container">
                  <Flex align="center" justify="space-between">
                    <Button
                      disabled={dateScrollIndex === 0}
                      icon={<ArrowLeftOutlined />}
                      shape="circle"
                      type="primary"
                      size="small"
                      onClick={handleScrollLeft}
                    />
                    <Flex align="center" gap={8}>
                      <CalendarOutlined />
                      <div className="date-scroll__date-label">
                        {dayjs(Object.keys(eventGroups)[dateScrollIndex]).format('dddd, MMM DD')}
                      </div>
                    </Flex>
                    <Button
                      disabled={dateScrollIndex === Object.keys(eventGroups).length - 1}
                      icon={<ArrowRightOutlined />}
                      shape="circle"
                      type="primary"
                      size="small"
                      onClick={handleScrollRight}
                    />
                  </Flex>
                </div>
                <div className="betting__events">
                  {(Object.values(eventGroups)[dateScrollIndex] || []).map((event: OddsEvent) => (
                    <BettingEvent key={event.id} event={event} handleNavigate={handleNavigate} />
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </Flex>
    );
  });

  const Component = memo(() => {
    return (
      <div>
        {isTabletOrMobile ? (
          <Routes>
            <Route
              path="/betting/:eventId"
              element={
                <div className="betting__container">
                  <Button
                    icon={<ArrowLeftOutlined />}
                    type="primary"
                    size={isTabletOrMobile ? 'small' : 'large'}
                    shape="round"
                    onClick={() => {
                      navigate(`/sports/${sportId}/betting`);
                    }}
                  >
                    Go Back
                  </Button>
                  <BettingEventMarkets eventId={eventId} sportsKey={sportsKey as string} />
                </div>
              }
            />
            <Route path="/betting" element={<BettingLanding isTabletOrMobile={isTabletOrMobile} />} />
          </Routes>
        ) : (
          <>
            <OurPicks sportId={sportId as Sport} condensed={condensedView} />
            <Flex gap={36} style={{ width: '100%' }}>
              <BettingLanding isTabletOrMobile={isTabletOrMobile} />
              <div style={{ flexGrow: 1, width: 200 }}>
                <BettingEventMarkets eventId={eventId} sportsKey={sportsKey as string} />
              </div>
            </Flex>
          </>
        )}
      </div>
    );
  });

  if (isTabletOrMobile) {
    return (
      <PullToRefresh onRefresh={handleRefresh}>
        <Component />
      </PullToRefresh>
    );
  }

  return <Component />;
}

export default Betting;
