import React from 'react';
import { Sport, NhlStatsMetric } from '../../types';
import type { TableProps } from 'antd';

interface DataType {
  key: string;
  game: string;
  gameDate: number;
}

const nbaColumns: TableProps<DataType>['columns'] = [
  {
    title: 'Mins',
    dataIndex: 'mins',
    key: 'mins',
    width: 75,
  },
  {
    title: 'Pts',
    dataIndex: 'pts',
    key: 'pts',
    width: 75,
  },
  {
    title: 'Fg',
    dataIndex: 'Fg',
    key: 'Fg',
    render: (_: string, record: any) => {
      return (
        <>
          {record.fgm}-{record.fga}
        </>
      );
    },
    width: 75,
  },
  {
    title: 'Fgp',
    dataIndex: 'fgp',
    key: 'fgp',
    width: 75,
  },
  {
    title: '3Fg',
    dataIndex: '3Fg',
    key: '3Fg',
    render: (_: string, record: any) => {
      return (
        <>
          {record.tptfgm}-{record.tptfga}
        </>
      );
    },
    width: 75,
  },
  {
    title: 'Ast',
    dataIndex: 'ast',
    key: 'ast',
    width: 75,
  },
  {
    title: 'Reb',
    dataIndex: 'reb',
    key: 'reb',
    width: 75,
  },
  {
    title: 'Blk',
    dataIndex: 'blk',
    key: 'blk',
    width: 75,
  },
  {
    title: 'To',
    dataIndex: 'TOV',
    key: 'TOV',
    width: 75,
  },
];

const nflColumns: Record<string, TableProps<DataType>['columns']> = {
  Passing: [
    {
      title: 'Passing',
      children: [
        {
          title: 'Att',
          dataIndex: 'pa',
          key: 'pa',
          width: 75,
          render: (_: string, record: any) => {
            return (
              <>
                {record['Passing.passCompletions']}-{record['Passing.passAttempts']}
              </>
            );
          },
        },
        {
          title: 'Yds',
          dataIndex: 'Passing.passYds',
          key: 'Passing.passYds',
          width: 50,
        },
        {
          title: 'Tds',
          dataIndex: 'Passing.passTD',
          key: 'Passing.passTD',
          width: 50,
        },
        {
          title: 'Ints',
          dataIndex: 'Passing.int',
          key: 'Passing.int',
          width: 50,
        },
      ],
    },
  ],
  Rushing: [
    {
      title: 'Rushing',
      children: [
        {
          title: 'Att',
          dataIndex: 'Rushing.carries',
          key: 'Rushing.carries',
          width: 50,
        },
        {
          title: 'Yds',
          dataIndex: 'Rushing.rushYds',
          key: 'Rushing.rushYds',
          width: 50,
        },
        {
          title: 'Lng Rush',
          dataIndex: 'Rushing.longRush',
          key: 'Rushing.longRush',
          width: 50,
        },
        {
          title: 'Tds',
          dataIndex: 'Rushing.rushTD',
          key: 'Rushing.rushTD',
          width: 50,
        },
      ],
    },
  ],
  Receiving: [
    {
      title: 'Receiving',
      children: [
        {
          title: 'Tgts',
          dataIndex: 'Receiving.targets',
          key: 'Receiving.targets',
          width: 50,
        },
        {
          title: 'Rec',
          dataIndex: 'Receiving.receptions',
          key: 'Receiving.receptions',
          width: 50,
        },
        {
          title: 'Yds',
          dataIndex: 'Receiving.recYds',
          key: 'Receiving.recYds',
          width: 50,
        },
        {
          title: 'Lng Rec',
          dataIndex: 'Receiving.longRec',
          key: 'Receiving.longRec',
          width: 50,
        },
        {
          title: 'Avg',
          dataIndex: 'Receiving.recAvg',
          key: 'Receiving.recAvg',
          width: 50,
        },
        {
          title: 'Tds',
          dataIndex: 'Receiving.recTD',
          key: 'Receiving.recTD',
          width: 50,
        },
      ],
    },
  ],
  Kicking: [
    {
      title: 'Kicking',
      children: [
        {
          title: 'Fgm',
          dataIndex: 'Kicking.fgMade',
          key: 'Kicking.fgMade',
          width: 50,
          render: (_: string, record: any) => {
            return (
              <>
                {record['Kicking.fgMade']}-{record['Kicking.fgAttempts']}
              </>
            );
          },
        },
        {
          title: 'Long',
          dataIndex: 'Kicking.fgLong',
          key: 'Kicking.fgLong',
          width: 50,
        },
        {
          title: 'Pct',
          dataIndex: 'Kicking.fgPct',
          key: 'Kicking.fgPct',
          width: 50,
        },
        {
          title: 'xpMade',
          dataIndex: 'Kicking.xpMade',
          key: 'Kicking.xpMade',
          width: 50,
          render: (_: string, record: any) => {
            return (
              <>
                {record['Kicking.xpMade']}-{record['Kicking.xpAttempts']}
              </>
            );
          },
        },
        {
          title: 'Pts',
          dataIndex: 'Kicking.kickingPts',
          key: 'Kicking.kickingPts',
          width: 50,
        },
      ],
    },
  ],
  Defense: [
    {
      title: 'Defense',
      children: [
        {
          title: 'Tackles',
          dataIndex: 'Defense.totalTackles',
          key: 'Defense.totalTackles',
          width: 50,
        },
        {
          title: 'Ints',
          dataIndex: 'Defense.defensiveInterceptions',
          key: 'Defense.defensiveInterceptions',
          width: 50,
        },
        {
          title: 'Sacks',
          dataIndex: 'Defense.sacks',
          key: 'Defense.sacks',
          width: 50,
        },
      ],
    },
  ],
};

const mlbColumns: Record<string, TableProps<DataType>['columns']> = {
  Pitching: [
    {
      title: 'Pitching',
      children: [
        {
          title: 'Count',
          dataIndex: 'Pitching.Pitches',
          key: 'Pitching.Pitches',
          width: 50,
        },
        {
          title: 'So',
          dataIndex: 'Pitching.SO',
          key: 'Pitching.SO',
          width: 50,
        },
        {
          title: 'Walks',
          dataIndex: 'Pitching.BB',
          key: 'Pitching.BB',
          width: 50,
        },
        {
          title: 'Balks',
          dataIndex: 'Pitching.Balk',
          key: 'Pitching.Balk',
          width: 50,
        },
        {
          title: 'H',
          dataIndex: 'Pitching.H',
          key: 'Pitching.H',
          width: 50,
        },
        {
          title: 'HR',
          dataIndex: 'Pitching.HR',
          key: 'Pitching.HR',
          width: 50,
        },
        {
          title: 'R',
          dataIndex: 'Pitching.R',
          key: 'Pitching.R',
          width: 50,
        },
      ],
    },
  ],
  Hitting: [
    {
      title: 'Hitting',
      children: [
        {
          title: 'HR',
          dataIndex: 'Hitting.HR',
          key: 'Hitting.HR',
          width: 50,
        },
        {
          title: 'H',
          dataIndex: 'Hitting.H',
          key: 'Hitting.H',
          width: 50,
        },
        {
          title: 'RBIs',
          dataIndex: 'Hitting.RBI',
          key: 'Hitting.RBI',
          width: 50,
        },
        {
          title: 'R',
          dataIndex: 'Hitting.R',
          key: 'Hitting.R',
          width: 50,
        },
        {
          title: 'TB',
          dataIndex: 'Hitting.TB',
          key: 'Hitting.TB',
          width: 50,
        },
        {
          title: 'SO',
          dataIndex: 'Hitting.SO',
          key: 'Hitting.SO',
          width: 50,
        },
        {
          title: 'W',
          dataIndex: 'Hitting.BB',
          key: 'Hitting.BB',
          width: 50,
        },
        {
          title: '2B',
          dataIndex: 'Hitting.2B',
          key: 'Hitting.2B',
          width: 50,
        },
        {
          title: '3B',
          dataIndex: 'Hitting.3B',
          key: 'Hitting.3B',
          width: 50,
        },
      ],
    },
  ],
  BaseRunning: [
    {
      title: 'Base Running',
      children: [
        {
          title: 'SB',
          dataIndex: 'BaseRunning.SB',
          key: 'BaseRunning.SB',
          width: 50,
        },
        {
          title: 'CS',
          dataIndex: 'BaseRunning.CS',
          key: 'BaseRunning.CS',
          width: 50,
        },
        {
          title: 'PO',
          dataIndex: 'BaseRunning.PO',
          key: 'BaseRunning.PO',
          width: 50,
        },
      ],
    },
  ],
};

const nhlColumns: Record<string, TableProps<DataType>['columns']> = {
  Goalie: [
    {
      title: 'Saves',
      dataIndex: NhlStatsMetric.GOALIE_SAVES,
      key: NhlStatsMetric.GOALIE_SAVES,
      width: 75,
    },
    {
      title: 'PP Saves',
      dataIndex: NhlStatsMetric.GOALIE_POWERPLAY_SAVES,
      key: NhlStatsMetric.GOALIE_POWERPLAY_SAVES,
      width: 75,
    },
    {
      title: 'SH Saves',
      dataIndex: NhlStatsMetric.GOALIE_SHORT_HANDED_SAVES,
      key: NhlStatsMetric.GOALIE_SHORT_HANDED_SAVES,
      width: 75,
    },
    {
      title: 'Goals',
      dataIndex: NhlStatsMetric.GOALIE_GOALS_AGAINST,
      key: NhlStatsMetric.GOALIE_GOALS_AGAINST,
      width: 75,
    },
    {
      title: 'Shots Agst',
      dataIndex: NhlStatsMetric.GOALIE_SHOTS_AGAINST,
      key: NhlStatsMetric.GOALIE_SHOTS_AGAINST,
      width: 75,
    },
  ],
  Metric: [
    {
      title: 'Points',
      dataIndex: NhlStatsMetric.POINTS,
      key: NhlStatsMetric.POINTS,
      width: 75,
      render: (_: string, record: any) => {
        return <>{+record[NhlStatsMetric.GOALS] + +record[NhlStatsMetric.ASSISTS]}</>;
      },
    },
    {
      title: 'PP Points',
      dataIndex: NhlStatsMetric.GOALIE_POWERPLAY_SAVES,
      key: NhlStatsMetric.GOALIE_POWERPLAY_SAVES,
      width: 75,
      render: (_: string, record: any) => {
        return <>{+record[NhlStatsMetric.POWERPLAY_GOALS] + +record[NhlStatsMetric.POWERPLAY_ASSISTS]}</>;
      },
    },
    {
      title: 'Goals',
      dataIndex: NhlStatsMetric.GOALS,
      key: NhlStatsMetric.GOALS,
      width: 75,
    },
    {
      title: 'Shots',
      dataIndex: NhlStatsMetric.SHOTS,
      key: NhlStatsMetric.SHOTS,
      width: 75,
    },
    {
      title: 'Blocked Shots',
      dataIndex: NhlStatsMetric.BLOCKED_SHOTS,
      key: NhlStatsMetric.BLOCKED_SHOTS,
      width: 75,
    },
    {
      title: 'Shots Missed Net',
      dataIndex: NhlStatsMetric.SHOTS_MISSED_NET,
      key: NhlStatsMetric.SHOTS_MISSED_NET,
      width: 75,
    },
    {
      title: 'PP Goals',
      dataIndex: NhlStatsMetric.POWERPLAY_GOALS,
      key: NhlStatsMetric.POWERPLAY_GOALS,
      width: 75,
    },
    {
      title: 'SH Goals',
      dataIndex: NhlStatsMetric.SHOOTOUT_GOALS,
      key: NhlStatsMetric.SHOOTOUT_GOALS,
      width: 75,
    },
    {
      title: 'Assists',
      dataIndex: NhlStatsMetric.ASSISTS,
      key: NhlStatsMetric.ASSISTS,
      width: 75,
    },
    {
      title: 'PP Assists',
      dataIndex: NhlStatsMetric.POWERPLAY_ASSISTS,
      key: NhlStatsMetric.POWERPLAY_ASSISTS,
      width: 75,
    },
  ],
};

export const tableStatColumns = (sport: Sport, position: string | undefined, isMobile: boolean) => {
  const commonColumns: TableProps<DataType>['columns'] = [
    {
      title: 'Game',
      dataIndex: 'game',
      key: 'game',
      fixed: 'left',
      width: 100,
    },
    {
      title: 'Date',
      dataIndex: 'gameDate',
      key: 'gameDate',
      ...(isMobile ? {} : { fixed: 'left' }),
      width: 100,
    },
  ];

  if (sport === Sport.nba) {
    return [...commonColumns, ...nbaColumns];
  }

  if (sport === Sport.nfl) {
    if (position === 'QB') {
      // @ts-ignore
      return [...commonColumns, ...nflColumns['Passing'], ...nflColumns['Rushing']];
    }
    if (position === 'RB') {
      // @ts-ignore
      return [...commonColumns, ...nflColumns['Rushing'], ...nflColumns['Receiving']];
    }
    if (position === 'TE' || position === 'WR') {
      // @ts-ignore
      return [...commonColumns, ...nflColumns['Receiving'], ...nflColumns['Rushing']];
    }
    if (position === 'PK') {
      // @ts-ignore
      return [...commonColumns, ...nflColumns['Kicking']];
    }
    if (/^DE|DT|CB|LB|S$/.test(position || '')) {
      // @ts-ignore
      return [...commonColumns, ...nflColumns['Defense']];
    }
    return [...commonColumns];
  }

  if (sport === Sport.mlb) {
    if (position === 'P') {
      // @ts-ignore
      return [...commonColumns, ...mlbColumns['Pitching'], ...mlbColumns['Hitting'], ...mlbColumns['BaseRunning']];
    }

    // @ts-ignore
    return [...commonColumns, ...mlbColumns['Hitting'], ...mlbColumns['BaseRunning']];
  }

  if (sport === Sport.nhl) {
    // @ts-ignore
    if (position === 'G') return [...commonColumns, ...nhlColumns['Goalie']];

    // @ts-ignore
    return [...commonColumns, ...nhlColumns['Metric']];
  }

  return [];
};
