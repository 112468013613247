import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { Flex, Button } from 'antd';
import { useGetUserInfo } from '../../hooks/useGetUser';
import MobileFreePicks from './MobileFreePicks';
import './Home.scss';

function Home() {
  const navigate = useNavigate();
  const { user, isLoading: userIsLoading } = useGetUserInfo();
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  useEffect(() => {
    if (user.isSignedIn) navigate('/sports/nfl');
  }, [user.isSignedIn, navigate]);

  if (userIsLoading) return null;

  const containerCss = cn('home-container', {
    'home-container--mobile': isTabletOrMobile,
  });

  const researchContainerCss = cn('research-container', {
    'research-container--mobile': isTabletOrMobile,
  });

  if (isTabletOrMobile)
    return (
      <>
        <div className={containerCss} style={{ backgroundImage: 'url("../../backgrounds/home-winners.png")' }}>
          <div className="home-container__title">
            Winning
            <br />
            Picks
          </div>
          <div className="home-container__preamble">
            We do the work so you don't have to. We pick the props to help you cash.
          </div>
          <MobileFreePicks />
        </div>

        <div className="free-access-research__wrapper">
          <div className="free-access-research__text">Get Free Access To our Reseach tool by Now.</div>
          <div className="free-access-research__button">
            <Button block size="large" onClick={() => navigate('/signup')}>
              FREE ACCESS
            </Button>
          </div>
        </div>

        <div className={researchContainerCss}>
          <div className="research-container__image">
            <img src="/logos/bookmakers/underdog.png" alt="underdog" className="small" />
          </div>
          <div className="research-container__title">Play on Underdog?</div>
          <div className="description__text">
            Our research tool is perfect for you. Pick your prop in underdog and use our research tool to make your
            decision.
          </div>
        </div>

        <div className="home-container__divider" />

        <div className={researchContainerCss}>
          <div className="research-container__image">
            <img src="/backgrounds/responsive_home_image.png" alt="responsive" />
          </div>
          <div className="research-container__title">Research</div>
          <div className="description__text">
            Research trends in player stats to make better decisions. View how a player does at home, away oe even
            against a particular opponent. We make it easy with a just a few clicks to give you confidence when placing
            your bet.
          </div>
        </div>

        <div className="home-container__divider" />

        <div className={researchContainerCss}>
          <div className="research-container__title">Betting</div>
          <div className="description__text">
            Compare lines from multiple bookmakers in one place showing the price a prop for a given player. Put your
            money on the books with the best value to maximize your profits.
          </div>
        </div>

        <div className="home-container__overlap home-container__overlap--mobile">
          <div className={'home-container__overlap-image image-1'}>
            <img src="/backgrounds/betting_phone.png" alt="" />
          </div>
          <div className={'home-container__overlap-image image-2'}>
            <img src="/backgrounds/research_phone2.png" alt="" />
          </div>
          <div className={'home-container__overlap-image image-3'}>
            <img src="/backgrounds/research_phone.png" alt="" />
          </div>
        </div>
      </>
    );

  return (
    <>
      <div className={containerCss}>
        <div className="home-container__body">
          <Flex align="center" justify="space-between">
            <div style={{ width: '60%' }}>
              <div className="home-container__title">Stop Losing Money!</div>
              <div className="home-container__subtitle">Use our tools before you wager.</div>
              <div className="home-container__preamble">
                Before you bet your hard earned money, use our tools to research the proper lines.
              </div>
            </div>
            <div className="home-container__image">
              <img src="/backgrounds/responsive_home_image.png" alt="" />
            </div>
          </Flex>
        </div>
      </div>
      <div className="research-container__wrapper">
        <Flex gap={48} align="flex-strt" justify="space-between" style={{ marginBottom: 32 }}>
          <div className={researchContainerCss}>
            <div className="research-container__title">Play on Underdog?</div>
            <Flex gap={16} align="center" justify="space-between">
              <div className="description__text">
                Our research tool is perfect for you. Pick your prop in underdog and use our research tool to make your
                decision.
              </div>
              <div>
                <img src="/logos/bookmakers/underdog.png" alt="underdog" />
              </div>
            </Flex>
          </div>
          <div className={researchContainerCss}>
            <div className="research-container__title">Research Tool</div>
            <div className="description__text">
              Research trends in player stats to make better decisions. View how a player does at home, away even
              against a particular opponent. We make it easy with a just a few clicks to give you confidence when
              placing your bet.
            </div>
          </div>
          <div className={researchContainerCss}>
            <div className="research-container__title">Betting</div>
            <div className="description__text">
              Compare lines from multiple bookmakers in one place showing the price a prop for a given player. Put your
              money on the books with the best value to maximize your profits.
            </div>
          </div>
          <div className={researchContainerCss}>
            <div className="research-container__title">Data</div>
            <div className="description__text">
              We carefully analyze a players stat history to deliver you the data that you need to make the best
              descisions with your money. You can view up to 20 game history to assess the direction of a players trend.
            </div>
          </div>
        </Flex>

        <div className="home-container__overlap">
          <div className={'home-container__overlap-image image-1'}>
            <img src="/backgrounds/betting_phone.png" alt="" />
          </div>
          <div className={'home-container__overlap-image image-2'}>
            <img src="/backgrounds/research_phone2.png" alt="" />
          </div>
          <div className={'home-container__overlap-image image-3'}>
            <img src="/backgrounds/research_phone.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
